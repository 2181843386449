import { message } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import {
  useDeleteBasicToolsMutation,
  useGetBasicToolsMutation,
} from "src/app/services/finance";
import {
  IBasicMonth,
  IBasicToolsRes,
  IBasicToolsTable,
} from "src/app/services/finance/type";
import useParamsHook from "src/hooks/params";
import { ICashflowTableItem } from "src/pages/sub_control_reports/cashflow/type";
import { TFunctionType } from "src/static/constants";
import { findMonthByIndex, handleMakeUrl } from "src/utils";

export default function useBasicTools() {
  //Get section
  const { searchParams } = useParamsHook();

  const [getFunc, { data: total }] = useGetBasicToolsMutation();
  const [data, setData] = useState<IBasicToolsRes[]>([]);
  const handleGet = async () => {
    let url = handleMakeUrl(searchParams);
    const res = await getFunc(url).unwrap();

    let arr: IBasicToolsTable[] = [];
    res.results.forEach((item) => {
      let obj: Partial<ICashflowTableItem> = {};

      item.months?.forEach((month: IBasicMonth) => {
        let month_key: keyof ICashflowTableItem = findMonthByIndex(
          Number(month.month)
        );
        if (month_key) obj[month_key] = Number(month.remaining.toFixed(1));
      });
      arr.push({ ...item, ...obj, key: item.id });
    });
    setData(arr);
    return arr;
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //Delete section
  const [deleteFunc, { isSuccess: deleteStatus }] =
    useDeleteBasicToolsMutation();
  useEffect(() => {
    if (deleteStatus) {
      handleGet();
      message.success("Item deleted successfully!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  //Edit section
  const [editableData, setEditableData] = useState<IBasicToolsRes>();
  return {
    data,
    total,
    editableData,
    handleGet,
    setEditableData,
    deleteFunc,
    getColumns,
  };
}

const getColumns = (t: TFunctionType) => {
  const basicTollsColumns: ColumnsType<IBasicToolsRes> = [
    {
      title: t("common.name"),
      dataIndex: "title",
      key: "title",
      width: 200,
    },
    {
      title: t("subHelper.purchaseDate"),
      dataIndex: "date",
      key: "date",
      width: 110,
    },
    {
      title: t("subHelper.initial_balance"),
      dataIndex: "initial_balance",
      key: "initial_balance",
      width: 200,
      render: (el: number) => el?.toLocaleString(),
    },
    {
      title: t("subHelper.current_balance"),
      dataIndex: "current_balance",
      key: "current_balance",
      width: 200,
      render: (el: number) => el?.toLocaleString(),
    },
    {
      title: t("subHelper.total_months"),
      dataIndex: "total_months",
      key: "total_months",
      width: 120,
    },
    {
      title: t("subHelper.monthly_rate"),
      dataIndex: "monthly_rate",
      key: "monthly_rate",
      width: 130,
      render: (el: number) => el?.toLocaleString(),
    },
    {
      title: t("subHelper.attritionMonthly"),
      children: [
        {
          title: t("months.jan"),
          dataIndex: "jan",
          key: "jan",
          width: 110,
          render: (val) => val?.toLocaleString(),
        },
        {
          title: t("months.feb"),
          dataIndex: "feb",
          key: "feb",
          width: 110,
          render: (val) => val?.toLocaleString(),
        },
        {
          title: t("months.march"),
          dataIndex: "mar",
          key: "mar",
          width: 110,
          render: (val) => val?.toLocaleString(),
        },
        {
          title: t("months.apr"),
          dataIndex: "apr",
          key: "apr",
          width: 110,
          render: (val) => val?.toLocaleString(),
        },
        {
          title: t("months.may"),
          dataIndex: "may",
          key: "may",
          width: 110,
          render: (val) => val?.toLocaleString(),
        },
        {
          title: t("months.jun"),
          dataIndex: "june",
          key: "june",
          width: 110,
          render: (val) => val?.toLocaleString(),
        },
        {
          title: t("months.jul"),
          dataIndex: "july",
          key: "july",
          width: 110,
          render: (val) => val?.toLocaleString(),
        },
        {
          title: t("months.aug"),
          dataIndex: "aug",
          key: "aug",
          width: 110,
          render: (val) => val?.toLocaleString(),
        },
        {
          title: t("months.sep"),
          dataIndex: "sep",
          key: "sep",
          width: 110,
          render: (val) => val?.toLocaleString(),
        },
        {
          title: t("months.oct"),
          dataIndex: "oct",
          key: "oct",
          width: 110,
          render: (val) => val?.toLocaleString(),
        },
        {
          title: t("months.nov"),
          dataIndex: "nov",
          key: "nov",
          width: 110,
          render: (val) => val?.toLocaleString(),
        },
        {
          title: t("months.dec"),
          dataIndex: "dec",
          key: "dec",
          width: 110,
          render: (val) => val?.toLocaleString(),
        },
      ],
    },
  ];

  return basicTollsColumns;
};
