import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { IBasicToolsRes } from "src/app/services/finance/type";
import PaginationFilter from "src/components/filter/PaginationFilter";
import TableActions from "src/components/view/TableActions";
import ContentTop from "src/layout/content/ContentTop";
import AddBasicTools from "./add";
import useBasicTools from "./useBasicTools";
import FilterDate from "src/components/filter/FilterDate";
import { useTranslation } from "react-i18next";

function BasicToolsPage() {
  const { t } = useTranslation();
  const {
    data,
    total,
    editableData,
    handleGet,
    setEditableData,
    deleteFunc,
    getColumns,
  } = useBasicTools();

  //Table section
  const columns: ColumnsType<IBasicToolsRes> = [
    ...getColumns(t),
    {
      title: t("common.actions"),
      dataIndex: "key",
      key: "key",
      width: 90,
      fixed: "right",
      render: (key, val) => (
        <TableActions
          onDelete={() => deleteFunc(key)}
          onEdit={() => setEditableData(val)}
        />
      ),
    },
  ];

  return (
    <div>
      <ContentTop>
        <FilterDate format="year" />
        <AddBasicTools
          handleGet={handleGet}
          editableData={editableData}
          setEditableData={setEditableData}
        />
      </ContentTop>

      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 2000 }}
        pagination={false}
      />
      {total && <PaginationFilter total={total?.count} />}
    </div>
  );
}

export default BasicToolsPage;
