import { Form, Input, message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "src/app/services/users";
import { ILogin } from "src/app/services/users/type";
import CustomButton from "src/components/common/button";
import AuthPage from "..";
import { signInFormData } from "../constants";
import { useTranslation } from "react-i18next";

function SignInPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [login, { isSuccess, error }] = useLoginMutation();
  const onFinish = (val: ILogin) => {
    login(val);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/dashboard");
      message.success("You have successfully logged in!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // useEffect(() => {
  //   let err: any = error;
  //   if (err) {
  //     if (err.status === 401) message.warning("Login yoki parol xato kiritldi. Iltimos tekshirib qaytadan kiriting.");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [error]);

  return (
    <AuthPage
      title={t("auth.signIn.formTitle")}
      imgUrl="sign-in.png"
      helpText={t("auth.signIn.bottomText")}
      navigateText={t("auth.signIn.commentLink")}
      navigateLink="/auth/comment"
    >
      <Form
        name="normal_login"
        className="form-form auth-form"
        onFinish={onFinish}
        layout={"vertical"}
        requiredMark={false}
      >
        {signInFormData.map((item) => {
          return (
            <Form.Item
              name={item.name}
              label={t(item.label)}
              // label={item.label + "*"}
              className={item.className}
              rules={[{ required: true, message: item.message }]}
              key={item.label}
            >
              {item.password ? (
                <Input.Password className="auth-form-input" />
              ) : (
                <Input className="auth-form-input" />
              )}
            </Form.Item>
          );
        })}
        <p className="forgot-password">
          <span>{t("auth.signIn.forgetPassword")}</span>
        </p>

        {/* Submit button */}
        <CustomButton
          htmlType="submit"
          className="auth-form-btn"
          style={{ marginTop: 24 }}
          height={40}
          fontSize={14}
          type="primary"
        >
          {t("auth.signIn.button")}
        </CustomButton>
      </Form>
    </AuthPage>
  );
}

export default SignInPage;
