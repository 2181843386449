import { Table } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { hostName } from "src/app/services/api/const";
import {
  useGenerateCashflowMutation,
  useGetPnlMutation,
} from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import FilterDate from "src/components/filter/FilterDate";
import ContentTop from "src/layout/content/ContentTop";
import { cashFlowColumns } from "src/pages/sub_control_reports/cashflow/constants";
import {
  ICashflowColumns,
  ICashflowPredColumns,
} from "src/pages/sub_control_reports/cashflow/type";
import {
  findMonthByIndex,
  handleMakeUrlNoPage,
  redirectToNewTab,
} from "src/utils";
import "./pnl.scss";
import { useTranslation } from "react-i18next";
import useBasicTools from "src/pages/sub_helper_reports/basic_tools/useBasicTools";
import { IBasicMonth, IBasicToolsRes } from "src/app/services/finance/type";

function PnlPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { screenMode } = useTypedSelector((state) => state.layout);
  const [getCashflow, { isLoading }] = useGetPnlMutation();

  const { pnl } = useTypedSelector((state) => state.pnl);

  const { handleGet: basicToolsData } = useBasicTools();
  const [deprecations, setDeprications] = useState<ICashflowPredColumns>({});
  const [deprecationsPercent, setDepricationsPercent] =
    useState<ICashflowPredColumns>({});

  const makeDeprectionDate = async () => {
    const data = await basicToolsData();
    const list = data.reduce(
      (acc: IBasicMonth[], item: IBasicToolsRes) => [...acc, ...item?.months],
      []
    );
    const listReduced: IBasicMonth[] = [];

    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((number) => {
      const months = list.filter((item) => item.month === number);
      const obj = {
        month: number,
        remaining: months.reduce((acc, item) => acc + item.remaining, 0),
        monthly_payment: months.reduce(
          (acc, item) => acc + item.monthly_payment,
          0
        ),
      };
      listReduced.push(obj);
    });

    const result: { [key: string]: number } = {};
    const resultPercent: { [key: string]: number } = {};

    listReduced.forEach((item: IBasicMonth) => {
      const monthName = findMonthByIndex(item.month);
      result[monthName] = item.monthly_payment;

      // set percent
      if (pnl && pnl.profit) {
        resultPercent[monthName] = !isFinite(
          item.monthly_payment / pnl.profit[monthName]
        )
          ? 0
          : (item.monthly_payment * 100) / pnl.profit[monthName];
      }
    });

    setDeprications(result);
    setDepricationsPercent(resultPercent);
  };

  useEffect(() => {
    let url = handleMakeUrlNoPage(searchParams);
    getCashflow(url);
    makeDeprectionDate();
  }, [searchParams]);

  const data: ICashflowColumns[] = [
    {
      key: "profits_green",
      sources: t("subCash.profit"),
      ...pnl?.profit,
      children: pnl?.profit_detail,
    },
    {
      key: "costs_pink",
      sources: t("common.value"),
      ...pnl?.cost,
    },
    {
      key: "costs_percentages_pink",
      sources: t("subCash.profitPercent"),
      ...pnl?.cost_percentages,
      children: pnl?.cost_detail,
    },
    {
      key: "margin_green",
      sources: t("subCash.marginProfit"),
      ...pnl?.margin,
    },
    {
      key: "margin_percentages_green",
      sources: t("subCash.marginPercent"),
      ...pnl?.margin_percentages,
      children: pnl?.margin_detail,
    },
    {
      key: "overhead_pink",
      sources: t("subCash.overheadOutcome"),
      ...pnl?.overhead,
    },
    {
      key: "overhead_percentages_pink",
      sources: t("subCash.profitPercent"),
      ...pnl?.overhead_percentages,
      children: pnl?.overhead_detail,
    },
    {
      key: "gross_green",
      sources: t("subCash.grossAll"),
      ...pnl?.gross,
    },
    {
      key: "gross_percentages_green",
      sources: t("subCash.grossRentabl"),
      ...pnl?.gross_percentages,
    },
    {
      key: "administrative_pink",
      sources: t("common.administrativeOutcome"),
      ...pnl?.administrative,
    },
    {
      key: "administrative_percentages_pink",
      sources: t("subCash.profitPercent"),
      ...pnl?.administrative_percentages,
      children: pnl?.administrative_detail,
    },
    {
      key: "commercial_pink",
      sources: t("common.commersialOutcome"),
      ...pnl?.commercial,
    },
    {
      key: "commercial_percentages_pink",
      sources: t("subCash.profitPercent"),
      ...pnl?.commercial_percentages,
      children: pnl?.commercial_detail,
    },
    {
      key: "operative_green",
      sources: t("subCash.operativeAll"),
      ...pnl?.operative,
    },
    {
      key: "operative_percentages_green",
      sources: t("subCash.operativRentabl"),
      ...pnl?.operative_percentages,
    },
    {
      key: "tax_pink",
      sources: t("common.taxes"),
      ...pnl?.tax,
    },
    {
      key: "tax_percentages_pink",
      sources: t("subCash.profitPercent"),
      ...pnl?.tax_percentages,
      children: pnl?.tax_detail,
    },
    {
      key: "deprecation_pink",
      sources: t("subCash.deprecation"),
      ...deprecations,
      // ...pnl?.deprecation,
      // ...makeDeprectionDate(),
    },
    {
      key: "deprecation_percentages_pink",
      sources: t("subCash.profitPercent"),
      // ...pnl?.deprecation_percentages,
      ...deprecationsPercent,
      children: pnl?.deprecation_detail,
    },
    {
      key: "soft_green",
      sources: t("subCash.sof"),
      ...pnl?.soft,
    },
    {
      key: "soft_percentages_green",
      sources: t("subCash.sofRentabl"),
      ...pnl?.soft_percentages,
    },
  ];

  //Generate section
  const [generateCashflow, { data: generatedUrl }] =
    useGenerateCashflowMutation();

  const generateExcel = () => {
    generateCashflow();
  };

  useEffect(() => {
    if (generatedUrl) redirectToNewTab(hostName + generatedUrl.link);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedUrl]);

  //Table height
  const [tableHeight, setTableHeight] = useState<number>();
  useEffect(() => {
    setTimeout(() => {
      setTableHeight(window.innerHeight - 163);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenMode]);

  return (
    <div className="pnl">
      <ContentTop>
        <div className="flex">
          {/* <CustomButton className="content-top-btn" height={38} onClick={generateExcel}>
            <div className="content-top-btn-content">
              <DownloadOutlined /> Excel yuklash
            </div>
          </CustomButton> */}
          <FilterDate format="year" />
        </div>
      </ContentTop>

      <div className="table">
        <Table
          scroll={{ x: 1200, y: tableHeight }}
          bordered={true}
          columns={cashFlowColumns(t)}
          dataSource={data}
          pagination={false}
          loading={isLoading}
          className="pnl_table"
        />
      </div>
    </div>
  );
}

export default PnlPage;
