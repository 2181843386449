import { ColumnsType } from "antd/es/table";
import { ISalaryTable } from "src/app/services/finance/type";
import { TFunctionType } from "src/static/constants";

export const salaryColumns = (t: TFunctionType): ColumnsType<ISalaryTable> => [
  {
    title: t("common.date"),
    dataIndex: "date",
    key: "date",
    render: (val) => val.slice(0, 7),
    width: 100,
  },
  {
    title: `${t("common.amount")} (${t("common.uzs")})`,
    dataIndex: "amount",
    key: "amount",
    width: 200,
    render: (el: number) => el.toLocaleString(),
    align: "center",
  },
  {
    title: t("common.user"),
    dataIndex: "employee",
    key: "employee",
    render: (val) => val?.full_name,
  },
  {
    title: t("common.Usource"),
    dataIndex: "source",
    key: "source",
    render: (val) => val?.title,
  },
];
