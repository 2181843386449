import { ColumnsType } from "antd/es/table";
import { ISourceResData } from "src/app/services/finance/type";
import { TFunctionType, expenseTypeOptions } from "src/static/constants";

export const sourceColumns = (
  t: TFunctionType
): ColumnsType<ISourceResData> => [
  {
    title: t("common.name"),
    dataIndex: "title",
    key: "title",
  },
  {
    title: t("sidebar.cashflow.title"),
    dataIndex: "category",
    key: "category",
    width: 170,
    render: (el) => el.title,
  },
  {
    title: t("common.sourceType"),
    dataIndex: "is_expense",
    key: "is_expense",
    width: 124,
    render: (is_expense) =>
      is_expense !== null ? (
        <div className={`status-column ${is_expense ? "outcome" : "income"}`}>
          <p>{is_expense ? t("common.outcome") : t("common.income")}</p>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: t("common.expenseType"),
    dataIndex: "type",
    key: "type",
    render: (val) => expenseTypeOptions.find((el) => el.value === val)?.label,
  },
  {
    title: t("common.subSources"),
    dataIndex: "comment",
    key: "comment",
  },
];
