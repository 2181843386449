import ReactEcharts from "echarts-for-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetCashBalanceStatMutation } from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import FilterAccount from "src/components/filter/FilterAccount";
import FilterDate from "src/components/filter/FilterDate";
import { handleMakeStatUrl } from "src/utils";
import ChartHeader from "../../components/chartHeader";
import "./balance.scss";
import { window_757 } from "src/static/constants";
import { useTranslation } from "react-i18next";

function BalancePage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  const { accounts } = useTypedSelector((state) => state.account);
  //Get section
  const [searchParams] = useSearchParams();
  const [getCashBalanceStat] = useGetCashBalanceStatMutation();
  const { balancesStat } = useTypedSelector((state) => state.stat);

  console.log(balancesStat);

  const handleGet = () => {
    let url = handleMakeStatUrl(searchParams, [
      "account_id",
      "date_month",
      "company_id",
    ]);
    getCashBalanceStat(url);
  };

  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams.get("account_id"),
    searchParams.get("date_month"),
    searchParams.get("company_id"),
  ]);

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      containLabel: true,
      height: 400,
    },
    xAxis: {
      type: "category",
      data: balancesStat?.xAxisData,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        height: 200,
      },
    },
    series: [
      {
        data: balancesStat?.yAxisData,
        type: "line",
        itemStyle: {
          color: colors.chart_colors[0],
        },
      },
    ],
  };

  return (
    <div className="balance" id="balance">
      <div className="balance-content">
        <ChartHeader
          className="balance-chart-header"
          name={t("subControlPanel.balance")}
          amount={balancesStat?.total}
          filters={
            <div className="chart-header-filters">
              <FilterAccount />
              <FilterDate format="month" name="date_month" />
            </div>
          }
        />
        <div className="statistics-chart balance-chart">
          <div className="balance-chart-content">
            <ReactEcharts
              opts={{ renderer: "svg", height: window_757 ? 500 : 586 }}
              option={option}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BalancePage;
