import { Flex, Form, Upload } from "antd";
import { open } from "fs";
import { useAddJournalExcelMutation } from "src/app/services/finance";
import { CustomUploadIcon } from "src/assets/svg";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";

export const UploadExcel = ({
  visible,
  setVisibleModal,
}: {
  visible: boolean;
  setVisibleModal: (val: boolean) => void;
}) => {
  const [form] = Form.useForm();
  const [addJournal, { isLoading }] = useAddJournalExcelMutation();

  const CustomDesign = () => (
    <Flex
      vertical
      gap={24}
      justify="center"
      align="center"
      className="customUpload"
    >
      <CustomUploadIcon />
      <p>Excel fileni bu yerga tashlang yoki shu yerni bosing !</p>
    </Flex>
  );

  const onCancel = () => {
    setVisibleModal(false);
    form.resetFields();
  };

  const onFinish = (val: any) => {
    const data = new FormData();
    data.append("file", val?.file?.file);
    addJournal(data).unwrap().then(onCancel);
  };

  return (
    <CustomModal open={visible} width={640} onCancel={onCancel}>
      <ModalHeader title={`Excel fileni yuklang`} />

      <Form
        form={form}
        name="normal_login"
        className="form-form"
        onFinish={onFinish}
        layout={"vertical"}
        requiredMark={false}
      >
        <Form.Item
          name="file"
          label={`Excel file` + "*"}
          rules={[{ required: true, message: `Excel fileni yuklang` }]}
          className="full"
        >
          <Upload.Dragger
            maxCount={1}
            multiple={false}
            beforeUpload={() => false}
          >
            <CustomDesign />
          </Upload.Dragger>
        </Form.Item>
        <ModalFooter onCancel={onCancel} okLoading={isLoading} />
      </Form>
    </CustomModal>
  );
};
