import { ConfigProvider, DatePicker, Flex, Spin } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  useGetJournalDiagramsMutation,
  useGetPCbetweenMutation,
} from "src/app/services/finance";
import locale from "antd/locale/en_GB";
import "dayjs/locale/en-gb";
import { prettierNumber } from "src/utils";
import { themeColors } from "src/static/theme";
dayjs.locale("en-gb");

export const PaymentCalendarCard = () => {
  const [date, setDate] = useState<null | string>(
    moment().format("YYYY-MM-DD")
  );
  const [from_date, setWeek] = useState<null | string>(null);
  const [to_date, setToWeek] = useState<null | string>(null);

  const [getPCbetween, { data: paymentCalendarData, isLoading }] =
    useGetPCbetweenMutation();
  const [getJournal, { data: journalCalendar }] =
    useGetJournalDiagramsMutation();

  const getData = () => {
    let params = `${from_date ? `from_date=${from_date}` : ""}${
      to_date ? `&to_date=${to_date}` : ""
    }${date ? `&date=${date}` : ""}`;

    if (params) {
      getPCbetween(params);
      getJournal(params);
    }
  };

  const giveColor = (number: number) => {
    return (
      <p
        style={
          Number(number) < 0
            ? { color: themeColors.light.color_red }
            : Number(number) > 0
            ? { color: themeColors.light.color_green }
            : {}
        }
      >
        {prettierNumber(number) || 0}
      </p>
    );
  };

  useEffect(() => {
    getData();
  }, [date, from_date]);

  return (
    <div className="dashboard-card payment-card" style={{ width: "100%" }}>
      <div className="dashboard-card-title">
        <p>To'lov kalendari</p>

        <Flex gap={8}>
          <ConfigProvider locale={locale}>
            <DatePicker
              value={from_date ? dayjs(from_date) : null}
              onChange={(val) => {
                setWeek(
                  moment(val?.toString())
                    .startOf("isoWeek")
                    .format("YYYY-MM-DD")
                );
                setToWeek(
                  moment(val?.toString()).endOf("isoWeek").format("YYYY-MM-DD")
                );
                setDate(null);
              }}
              picker="week"
            />
          </ConfigProvider>
          <DatePicker
            value={date ? dayjs(date) : null}
            onChange={(val) => {
              setDate(moment(val.toString()).format("YYYY-MM-DD"));
              setWeek(null);
            }}
          />
        </Flex>
      </div>
      <div className="cash-card-body">
        <Spin spinning={isLoading}>
          <Flex vertical className="customTable">
            <Flex justify="space-between" align="center">
              <h2>Nomi</h2>
              <p>Reja</p>
              <p>Fakt</p>
            </Flex>
            <Flex justify="space-between" align="center">
              <h2>Kirim</h2>
              {giveColor(paymentCalendarData?.[0]?.income_sum || 0)}
              {giveColor(journalCalendar?.[0]?.income_sum || 0)}
            </Flex>
            <Flex justify="space-between" align="center">
              <h2>Chiqim</h2>
              {giveColor(paymentCalendarData?.[0]?.outcome_sum || 0)}
              {giveColor(journalCalendar?.[0]?.outcome_sum || 0)}
            </Flex>
            <Flex justify="space-between" align="center">
              <h2>O'zgarish</h2>
              {giveColor(paymentCalendarData?.[0]?.balance || 0)}
              {giveColor(journalCalendar?.[0]?.balance || 0)}
            </Flex>
          </Flex>
        </Spin>
      </div>
    </div>
  );
};
