import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { IPurchaseRes } from "src/app/services/finance/type";
import PaginationFilter from "src/components/filter/PaginationFilter";
import TableActions from "src/components/view/TableActions";
import ContentTop from "src/layout/content/ContentTop";
import AddPurchase from "./add";
import { purchaseColumns } from "./constants";
import usePurchase from "./usePurchase";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import CustomButton from "src/components/common/button";
import SubHelperFilters from "../akt/components/SubHelperFilters";
import { FilterOutlined } from "@ant-design/icons";

function PurchasePage() {
  const { t } = useTranslation();
  const {
    data,
    total,
    editableData,
    isLoading,
    handleGet,
    setEditableData,
    deleteFunc,
  } = usePurchase();
  const [filterVisible, setFilterVisible] = useState(false);

  //Table section
  const columns: ColumnsType<IPurchaseRes> = [
    ...purchaseColumns(t),
    {
      title: t("common.actions"),
      dataIndex: "key",
      key: "key",
      width: 90,
      fixed: "right",
      render: (key, val) => (
        <TableActions
          onDelete={() => deleteFunc(key)}
          onEdit={() => setEditableData(val)}
        />
      ),
    },
  ];

  return (
    <div>
      <ContentTop>
        <CustomButton
          type={filterVisible ? "primary" : "default"}
          height={38}
          onClick={() => setFilterVisible(!filterVisible)}
          icon={<FilterOutlined />}
          style={filterVisible ? { color: "white" } : {}}
          className={`btn-primary ${!filterVisible && "journal-button"}`}
        >
          {t("common.filter")}
        </CustomButton>

        <AddPurchase
          handleGet={handleGet}
          editableData={editableData}
          setEditableData={setEditableData}
        />
      </ContentTop>

      {filterVisible && (
        <SubHelperFilters
          contingent
          amountLabel="total_amount"
        ></SubHelperFilters>
      )}

      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 900 }}
        pagination={false}
        loading={isLoading}
      />
      {total && <PaginationFilter total={total?.count} />}
    </div>
  );
}

export default PurchasePage;
