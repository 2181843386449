import { DatePickerProps, Form, Input } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IFilterJournal } from "src/app/services/finance/type";
import { useTypedSelector } from "src/app/store";
import ModalFooter from "src/components/common/modal/ModalFooter";
import { dateFormat } from "src/components/filter/FilterDate";
import AccountFormItem from "src/components/formItem/AccountFormItem";
import ContingentFormItem from "src/components/formItem/ContingentFormItem";
import CurrencyFormItem from "src/components/formItem/CurrencyFormItem";
import DateFormItem from "src/components/formItem/DateFormItem";
import DirectionFormItem from "src/components/formItem/DirectionFormItem";
import IntervalDateFormItem from "src/components/formItem/IntervalDateFormItem";
import SourceFormItem from "src/components/formItem/SourceFormItem";
import TeamFormItem from "src/components/formItem/TeamFormItem";

interface Props {
  income?: boolean;
  outcome?: boolean;
  date?: boolean;
  contingent?: boolean;
  currency?: boolean;
  source?: boolean;
  team?: boolean;
  direction?: boolean;
  account?: boolean;
  amount?: boolean;
  amountLabel?: string;
  children?: React.ReactNode;
}

function SubHelperFilters({
  income = false,
  outcome = false,
  date = true,
  contingent = false,
  currency = false,
  source = false,
  team = false,
  direction = false,
  account = false,
  amount = true,
  amountLabel = "amount",
  children,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleMakeParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const [form] = Form.useForm();
  const onFinish = (val: IFilterJournal) => {
    let { date, interval_date, ...rest } = val;
    if (date && !Array.isArray(date))
      handleMakeParams("date", date?.format("YYYY-MM-DD") || "");
    if (interval_date) {
      handleMakeParams(
        "from_date",
        interval_date[0]?.format("YYYY-MM-DD") || ""
      );
      handleMakeParams("to_date", interval_date[1]?.format("YYYY-MM-DD") || "");
    }

    for (const [key, value] of Object.entries(rest)) {
      handleMakeParams(key, String(value || ""));
    }
  };

  const handleReset = () => {
    setSearchParams();
    form.resetFields();
  };

  useEffect(() => {
    let date_arr: DatePickerProps["value"][] = [];
    for (let key of Array.from(searchParams.keys())) {
      let value = searchParams.get(key);
      if (key === "date") {
        form.setFieldValue("date", dayjs(value, dateFormat));
      } else if (key === "from_date") {
        date_arr.unshift(dayjs(value, dateFormat));
      } else if (key === "to_date") {
        date_arr.push(dayjs(value, dateFormat));
      } else {
        form.setFieldValue(key, value || "");
        if (key === "account" && value) setAccountId(value);
      }

      if (date_arr.length > 0) form.setFieldValue("interval_date", date_arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { accounts } = useTypedSelector((state) => state.account);
  const [accountId, setAccountId] = useState("");
  useEffect(() => {
    let curr = accounts?.find((el) => String(el.id) === accountId)?.currency;
    if (curr) {
      form.setFieldsValue({ currency: curr });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return (
    <div className="journal-filters">
      <Form
        form={form}
        requiredMark={false}
        name="normal_login"
        className="form-form"
        onFinish={onFinish}
        layout={"vertical"}
      >
        {/* Children element */}
        {children}

        {/* One date */}
        {date && <DateFormItem required={false} className="w-150" />}

        {/* INterval date */}
        <IntervalDateFormItem
          required={false}
          className="w-300"
          name="interval_date"
          label={t("common.intervalDate")}
        />

        {/* Amount */}
        {amount && (
          <Form.Item
            name={amountLabel}
            label={t("common.amount")}
            className="w-150"
          >
            <Input type="number" />
          </Form.Item>
        )}

        {/* Contingent */}
        {contingent && <ContingentFormItem className="w-250" />}

        {/* Direction */}
        {direction && <DirectionFormItem className="w-250" />}

        {/* Account */}
        {account && (
          <AccountFormItem
            className="w-250"
            onChange={(val) => setAccountId(val)}
          />
        )}

        {/* Currency */}
        {currency && (
          <CurrencyFormItem className="w-150" disabled={!!accountId} />
        )}

        {/* Income */}
        {income && (
          <Form.Item name="income" label={t("common.income")} className="w-150">
            <Input type="number" />
          </Form.Item>
        )}

        {/* Outcome */}
        {outcome && (
          <Form.Item
            name="outcome"
            label={t("common.outcome")}
            className="w-150"
          >
            <Input type="number" />
          </Form.Item>
        )}

        {/* Team */}
        {team && <TeamFormItem className="w-250" />}

        {/* <Form.Item
          name="month"
          label={t("common.whichMonth")}
          className="w-150"
          tooltip={{ title: t("common.whichMonthInfo") }}
        >
          <InputNumber min={1} type="number" style={{ width: "100%" }} />
        </Form.Item> */}

        {/* Source */}
        {source && (
          <SourceFormItem className="w-250" required={false} allData />
        )}

        <Form.Item name="comment" label={t("common.comment")} className="w-250">
          <Input />
        </Form.Item>

        <ModalFooter
          onCancel={handleReset}
          okText={t("common.search")}
          cancelText={t("common.clear")}
        />
      </Form>
    </div>
  );
}

export default SubHelperFilters;
