import type { DatePickerProps } from "antd";
import { DatePicker, Form } from "antd";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { formattedToday, formattedYesterday } from "src/static/constants";
import "./filter.scss";
import { useTranslation } from "react-i18next";

export const dateFormat = "YYYY-MM-DD";
export const monthFormat = "YYYY-MM";
export const yearFormat = "YYYY";

interface Props {
  label?: string;
  format?: "date" | "month" | "year";
  name?: "date" | "date_month" | "date_year" | "year";
  large?: boolean;
  page?: string;
  firthMonth?: boolean;
  lastMonth?: boolean;
  noShadow?: boolean;
}

function FilterDate({
  label = "",
  format = "date",
  name = "date",
  large,
  page,
  firthMonth = false,
  lastMonth = false,
  noShadow = false,
}: Props) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange: DatePickerProps["onChange"] = (_, dateString) => {
    if (page === "dashboard") {
      if (dateString === formattedToday) handleMakeParams("type", "today");
      else if (dateString === formattedYesterday)
        handleMakeParams("type", "yesterday");
      else handleMakeParams("type", "day");
    }
    if (firthMonth) {
      typeof dateString === "string" && dateString
        ? handleMakeParams(name, `${dateString}-01`)
        : handleMakeParams(name, "");
      return;
    }
    if (lastMonth) {
      typeof dateString === "string" && dateString
        ? handleMakeParams(
            name,
            dayjs(dateString).endOf("month").format("YYYY-MM-DD")
          )
        : handleMakeParams(name, "");
      return;
    }
    typeof dateString === "string" && handleMakeParams(name, dateString);
  };

  const handleMakeParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const defDate = searchParams.get(name);
  const defFormat =
    format === "date"
      ? dateFormat
      : format === "month"
      ? monthFormat
      : yearFormat;

  return (
    <div className="filter-date" style={{ boxShadow: noShadow ? "none" : "" }}>
      <Form.Item label={label}>
        <DatePicker
          format={defFormat}
          picker={
            format === "date" ? "date" : format === "month" ? "month" : "year"
          }
          onChange={onChange}
          // bordered={false}
          value={defDate ? dayjs(defDate, defFormat) : undefined}
          size={large ? "large" : "middle"}
          placeholder={
            format === "date"
              ? t("common.selectDate")
              : format === "month"
              ? t("common.selectMonth")
              : t("common.selectYear")
          }
        />
      </Form.Item>
    </div>
  );
}

export default FilterDate;
