import { Menu } from "antd";
import type { MenuProps } from "antd/es/menu";
import {
  Bank,
  BookSquare,
  ChartSuccess,
  Copyright,
  DocumentCopy,
  DocumentText1,
  EmptyWalletChange,
  InfoCircle,
  StatusUp,
  TableDocument,
} from "iconsax-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { position } from "src/app/services/users/const";
import { changeCollapsed, changeTheme } from "src/app/slices/layoutSlice";
import { useAppDispatch, useTypedSelector } from "src/app/store";
import { MinusIcon } from "src/assets/svg";
import { isSidebarFixed } from "src/static/constants";
import {
  basicTarifMenuKeys,
  findChildrenOpenKeys,
  findOpenKeys,
} from "../util";
import "./sidebar.scss";

export type MenuItem = Required<MenuProps>["items"][number];

interface IMenuItem {
  key: string;
  label: string;
  icon: React.ReactNode;
  children?: IMenuItem[];
}

function Sidebar() {
  const navigate = useNavigate();
  const { collapsed, darkMode, colors } = useTypedSelector(
    (state) => state.layout
  );
  const { profile } = useTypedSelector((state) => state.users);
  const userPostion = profile?.position || position;

  const location = useLocation();
  const { t } = useTranslation();

  // ---------------- Sidebar menu --------------------

  const dashboardMenuItem = {
    key: "",
    label: t("dashboard.title"),
    icon: <BookSquare size="24" color={colors.primary} />,
  };

  const subStatisticsMenuItem = {
    key: "sub_control_panel",
    icon: <StatusUp size="24" color={colors.primary} />,
    label: t("sidebar.statistics.title"),
    children: [
      {
        key: "cashflowStats#balance",
        label: t("sidebar.statistics.menu1"),
      },
      {
        key: "pnlStats#profit_soft",
        label: t("sidebar.statistics.menu2"),
      },
    ],
  };

  const subCheckMenuItem = {
    key: "sub_check",
    label: t("sidebar.check.title"),
    icon: <ChartSuccess size="24" color={colors.primary} />,
    children: [
      // {
      //   key: "unConfirmed",
      //   label: "Tasdiqlanmagan",
      // },
      // {
      //   key: "pending",
      //   label: "Javob berilmagan",
      // },
      {
        key: "edited",
        label: t("sidebar.check.menu1"),
      },
    ],
  };

  const subCashMenuItem = {
    key: "sub_cash",
    label: t("sidebar.cash.title"),
    icon: <EmptyWalletChange size="24" color={colors.primary} />,
    children: [
      {
        key: "cash",
        label: t("sidebar.cash.menu1"),
      },
      {
        key: "monitor",
        label: t("sidebar.cash.menu2"),
      },
      {
        key: "transfer",
        label: t("sidebar.cash.menu3"),
      },
    ],
  };

  const controlReportsMenuItem = {
    key: "sub_control_reports",
    label: t("subControlPanel.title"),
    icon: <DocumentText1 size="24" color={colors.primary} />,
    children: [
      {
        key: "cashflow",
        label: t("sidebar.cashflow.title"),
      },
      {
        key: "pnl",
        label: t("sidebar.pnl.menu1"),
      },
      {
        key: "balance",
        label: t("subCash.balans"),
      },
    ],
  };

  const helperReportsMenuItem = {
    key: "sub_helper_reports",
    label: t("subHelper.title"),
    icon: <DocumentCopy size="24" color={colors.primary} />,
    children: [
      {
        key: "salary",
        label: t("subHelper.salary"),
      },
      {
        key: "commitment",
        label: t("sidebar.pnl.menu2"),
      },
      {
        key: "akt",
        label: t("sidebar.pnl.menu3"),
      },
      // {
      //   key: "f_calendar",
      //   label: t("sidebar.pnl.f_calendar"),
      // },
      {
        key: "debtor_creditor",
        label: t("subHelper.debitCredit"),
      },
      {
        key: "purchase",
        label: t("subHelper.purchase"),
      },
      {
        key: "basic_tools",
        label: t("subHelper.mainToll"),
      },
      {
        key: "storage",
        label: t("subHelper.storage"),
      },
    ],
  };

  const planningMenuItem = {
    key: "sub_planning",
    label: t("sidebar.planned.title"),
    icon: <TableDocument size="24" color={colors.primary} />,
    children: [
      {
        key: "payment_calendar",
        label: t("subHelper.payment_calendar"),
      },
      {
        key: "sub_budget_cashflow",
        label: t("subCash.budgetTitle"),
        children: [
          {
            key: "budget_cashflow_list",
            label: t("sidebar.planned.menu1"),
          },
          {
            key: "budget_cashflow_by_month",
            label: t("sidebar.planned.menu2"),
          },
        ],
      },
      {
        key: "sub_budget_pnl",
        label: t("subCash.budgetPnlTitle"),
        children: [
          {
            key: "budget_pnl_list",
            label: t("subCash.budget_pnl_list"),
          },
          {
            key: "budget_pnl_by_month",
            label: t("subCash.budget_pnl_by_month"),
          },
        ],
      },
    ],
  };

  const subInfoMenuItem = {
    key: "sub_info",
    label: t("sidebar.info.title"),
    icon: <InfoCircle size="24" color={colors.primary} />,
    children: [
      {
        key: "currency",
        label: t("sidebar.info.menu1"),
      },
      {
        key: "source",
        label: t("sidebar.info.menu2"),
      },
      {
        key: "account",
        label: t("sidebar.info.menu3"),
      },
      {
        key: "direction",
        label: t("sidebar.info.menu4"),
      },
      {
        key: "team",
        label: t("sidebar.info.menu5"),
      },
      {
        key: "contingent",
        label: t("sidebar.info.menu6"),
      },
    ],
  };

  const companyMenuItem = {
    key: "company",
    label: t("sidebar.kompany.title"),
    icon: <Copyright size="24" color={colors.primary} />,
  };

  const branchsMenuItem = {
    key: "branchs",
    label: t("sidebar.branchs.title"),
    icon: <Bank size="24" color={colors.primary} />,
  };

  const ceoMenuItems = [
    dashboardMenuItem,
    subStatisticsMenuItem,
    subCheckMenuItem,
    subCashMenuItem,
    controlReportsMenuItem,
    helperReportsMenuItem,
    planningMenuItem,
    subInfoMenuItem,
    companyMenuItem,
  ];
  if (
    !profile?.parent_company?.is_branch &&
    !["cfo", "operator", "accountant"].includes(String(userPostion))
  ) {
    ceoMenuItems.push(branchsMenuItem);
  }

  const accountantMenuItems = [
    dashboardMenuItem,
    subCashMenuItem,
    helperReportsMenuItem,
    subInfoMenuItem,
  ];

  //  ------------ /Sidebar menu ---------------

  const dispatch = useAppDispatch();
  const toggleCollapsed = () => {
    dispatch(changeCollapsed());
  };

  const onMenuItemClick = (key: string) => {
    navigate(`/dashboard/${key}`);
    if (isSidebarFixed) toggleCollapsed();
  };

  const sidebar = document.querySelector(".sidebar-main") as HTMLElement;

  const showScrollbar = (): void => {
    sidebar.classList.add("scrolling-active");
  };

  const hideScrollbar = (): void => {
    sidebar.classList.remove("scrolling-active");
  };

  if (sidebar) {
    let hideScrollbarTimeout: number;

    sidebar.addEventListener("scroll", () => {
      showScrollbar();

      window.clearTimeout(hideScrollbarTimeout);
      hideScrollbarTimeout = window.setTimeout(hideScrollbar, 2000);
    });
  } else {
    // console.error("Sidebar element not found");
  }

  //Make menu items section
  const tariff = profile?.subscription_plan.code;
  // const tariff = "basic";

  const [menuItems, setMenuItems] = useState<MenuItem[]>();
  const makeMenuItems = () => {
    if (userPostion && tariff) {
      let arr: MenuItem[] = [];
      const itemsArr: IMenuItem[] =
        userPostion === "accountant" ? accountantMenuItems : ceoMenuItems;
      itemsArr.forEach((item) => {
        let obj: MenuItem = item;
        if (item.children) {
          if (tariff === "basic")
            item.children = item.children.filter((el) =>
              basicTarifMenuKeys.includes(el.key)
            );
          item.children.forEach((el) => {
            let obj2: MenuItem = el;
            obj2.icon = <MinusIcon />;
            if (el.children) {
              el.children.forEach((val) => {
                let obj3: MenuItem = val;
                obj3.onClick = () => onMenuItemClick(val.key);
                obj3.icon = <div className="sidebar-circle" />;
              });
            } else {
              obj2.onClick = () => onMenuItemClick(el.key);
            }
          });
        } else {
          obj.onClick = () => onMenuItemClick(item.key);
        }

        if (tariff === "basic") {
          if (basicTarifMenuKeys.includes(item.key)) arr.push(obj);
        } else arr.push(obj);
      });
      setMenuItems([...arr]);
    }
  };

  useEffect(() => {
    makeMenuItems();
  }, [userPostion, tariff]);

  const defPath = location.pathname.split("/")[2];
  const defKey = findOpenKeys(location.pathname.split("/")[2]);
  const defChildrenKey = findChildrenOpenKeys(location.pathname.split("/")[2]);

  return (
    <div
      className={`sidebar ${collapsed ? "collapsed" : ""} sidebar-fixed`}
      onClick={(e) => e.stopPropagation()}
      // onMouseEnter={() => !isSidebarFixed && toggleCollapsed()}
      // onMouseLeave={() => !isSidebarFixed && toggleCollapsed()}
    >
      <Link
        to="/"
        className="sidebar-header"
        onClick={() => dispatch(changeTheme("light"))}
        style={{ width: collapsed ? "5.5rem" : "21.8rem" }}
      >
        <div
          className={`sidebar-header-img ${
            darkMode && "sidebar-header-img-dark"
          }`}
        />
        {/* <img
          src={require(`src/assets/img/logo.png`)}
          // src={require(`src/assets/img/${collapsed ? "coin-logo" : "logo"}.png`)}
          alt="logo"
          className="logo-title"
        /> */}

        {/* {!collapsed && <p className="sidebar-header-title">AQCHAM</p>} */}
      </Link>
      <div className="sidebar-main">
        {userPostion && (
          <Menu
            defaultSelectedKeys={[
              defPath || (userPostion === "accountant" ? "cash" : "balance"),
            ]}
            defaultOpenKeys={[
              defKey ||
                (userPostion === "accountant"
                  ? "sub_cash"
                  : "sub_control_panel"),
              defChildrenKey || "",
            ]}
            mode="inline"
            items={menuItems}
            inlineCollapsed={collapsed}
            style={collapsed ? { width: 50 } : {}}
          />
        )}
      </div>
    </div>
  );
}

export default Sidebar;
