import moment from "moment";
import { useEffect, useState } from "react";
import {
  useGetJournalDiagramsMutation,
  useGetPCbetweenMutation,
} from "src/app/services/finance";
import ReactEcharts from "echarts-for-react";
import { useTypedSelector } from "src/app/store";
import { DatePicker, Spin } from "antd";
import dayjs from "dayjs";

export const PaymentCalendarChart = () => {
  const { colors } = useTypedSelector((state) => state.layout);
  const [date, setDate] = useState<null | string>(moment().format("YYYY"));
  const [getPCbetween, { data: paymentCalendarData, isLoading }] =
    useGetPCbetweenMutation();
  const [getJournal, { data: journalCalendar, isLoading: jLoading }] =
    useGetJournalDiagramsMutation();

  const getFirstLastWeek = (date: string, firstDay: boolean) => {
    return firstDay
      ? moment(date).startOf("year").startOf("isoWeek").format("YYYY-MM-DD")
      : moment(date).endOf("year").endOf("isoWeek").format("YYYY-MM-DD");
  };

  const getData = () => {
    let params = `from_date=${getFirstLastWeek(
      String(date),
      true
    )}&to_date=${getFirstLastWeek(String(date), false)}`;

    if (params) {
      getPCbetween(params);
      getJournal(params);
    }
  };

  useEffect(() => {
    getData();
  }, [date]);

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    grid: {
      left: "3%",
      top: "10%",
      containLabel: true,
      height: 240,
    },
    xAxis: {
      type: "category",
      data: Array.from({ length: paymentCalendarData?.length || 53 }, (_, i) =>
        String(i + 1)
      ),
      axisLine: {
        lineStyle: {
          color: "white",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        height: 200,
      },
      axisLine: {
        lineStyle: {
          color: "white",
        },
      },
    },

    series: [
      {
        name: "Reja",
        data: paymentCalendarData?.map((item) => String(item.balance)),
        type: "line",
        itemStyle: {
          color: colors.chart_colors[0],
        },
        markArea: {
          itemStyle: {
            color: "rgba(255, 173, 177, 0.25)",
          },
          data: [
            [
              {
                yAxis: "0",
              },
              {
                yAxis: "-10000000000000000",
              },
            ],
          ],
        },
      },
      {
        name: "Fakt",
        data: journalCalendar?.map((item) => String(item.balance)),
        type: "line",
        itemStyle: {
          color: colors.chart_colors[1],
        },
      },
    ],
  };

  return (
    <div className="dashboard-card payment-chart">
      <div className="dashboard-card-title">
        <p>To'lov kalendari grafigi</p>
        <DatePicker
          picker="year"
          value={date ? dayjs(date) : null}
          onChange={(val) => {
            setDate(moment(val.toString()).format("YYYY"));
          }}
        />
      </div>
      <div className="body">
        <Spin spinning={isLoading || jLoading}>
          <ReactEcharts opts={{ renderer: "svg" }} option={option} />
        </Spin>
      </div>
    </div>
  );
};
