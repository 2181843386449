import { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { setTheme } from "src/app/slices/layoutSlice";
import { useTypedSelector } from "src/app/store";
import ChatPage from "src/components/chat";
import DashboardLayout from "src/layout";
import Custom404 from "src/pages/404";
import CommentPage from "src/pages/auth/comment";
import SignInPage from "src/pages/auth/signIn";
import JournalPage from "src/pages/sub_cash/journal";
import MonitorPage from "src/pages/sub_cash/monitor";
import TransferPage from "src/pages/sub_cash/transfer";
import CashFlowPage from "src/pages/sub_control_reports/cashflow";
import CashFlowPredictedPage from "src/pages/sub_control_reports/cashflow/predicted";
import CompanyPage from "src/pages/company";
import DashboardPage from "src/pages/dashboard";
import DebtCreditPage from "src/pages/sub_helper_reports/debt_credit";
import HomePage from "src/pages/home";
import AccountPage from "src/pages/sub_info/account";
import ContingentPage from "src/pages/sub_info/contingent";
import CurrencyPage from "src/pages/sub_info/currency";
import DirectionPage from "src/pages/sub_info/direction";
import SourcePage from "src/pages/sub_info/source";
import TeamPage from "src/pages/sub_info/team";
import AktPage from "src/pages/sub_helper_reports/akt";
import PnlPage from "src/pages/sub_control_reports/pnl";
import Edited from "src/pages/sub_check/edited";
import Pending from "src/pages/sub_check/pending";
import UnConfirmed from "src/pages/sub_check/unConfirmed";
import CashflowStats from "src/pages/sub_control_panel/cashflow";
import PnlStats from "src/pages/sub_control_panel/pnl";
import CommitmentPage from "src/pages/sub_helper_reports/commitment";
import SalaryPage from "src/pages/sub_helper_reports/salary";
import BudgetPnlByMonthPage from "src/pages/sub_planning/budget_pnl/by_month";
import BudjetPnlListPage from "src/pages/sub_planning/budget_pnl/list";
import BasicToolsPage from "src/pages/sub_helper_reports/basic_tools";
import PurchasePage from "src/pages/sub_helper_reports/purchase";
import StoragePage from "src/pages/sub_helper_reports/storage";
import BranchesPage from "src/pages/branches";
import FinancialReportPage from "src/pages/sub_helper_reports/financial_report";
import React from "react";

const BalancePage = React.lazy(
  () => import("src/pages/sub_control_reports/balance")
);

function RoutElements() {
  const { profile } = useTypedSelector((state) => state.users);
  // const tariff = profile?.subscription_plan === 1?""

  //Set default colors
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTheme());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Checking chat availabilaty
  const location = useLocation();
  const isAvailableChat = location.pathname.split("/")[1] === "dashboard";

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="root">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/auth">
            {/* <Route path="signup" element={<SignUpPage />} /> */}
            <Route path="signin" element={<SignInPage />} />
            <Route path="comment" element={<CommentPage />} />
          </Route>
          <Route path="/dashboard" element={<DashboardLayout />}>
            {profile && profile.company ? (
              <>
                {/* Dashboard */}
                <Route index element={<DashboardPage />} />

                {/* Statistic sub */}
                <Route path="cashflowStats" element={<CashflowStats />} />
                <Route path="pnlStats" element={<PnlStats />} />

                {/* Check sub */}
                <Route path="edited" element={<Edited />} />
                <Route path="unConfirmed" element={<UnConfirmed />} />
                <Route path="pending" element={<Pending />} />

                {/* Cash sub */}
                <Route path="cash" element={<JournalPage />} />
                <Route path="monitor" element={<MonitorPage />} />
                <Route path="transfer" element={<TransferPage />} />

                {/* Control reports sub */}
                <Route path="cashflow" element={<CashFlowPage />} />
                <Route path="pnl" element={<PnlPage />} />

                {/* Helper reports sub */}
                <Route
                  path="payment_calendar"
                  element={<JournalPage isPaymentCalendar />}
                />
                <Route path="commitment" element={<CommitmentPage />} />
                <Route path="salary" element={<SalaryPage />} />
                <Route path="akt" element={<AktPage />} />
                <Route path="f_calendar" element={<FinancialReportPage />} />
                <Route path="debtor_creditor" element={<DebtCreditPage />} />
                <Route path="purchase" element={<PurchasePage />} />
                <Route path="basic_tools" element={<BasicToolsPage />} />
                <Route path="storage" element={<StoragePage />} />

                {/*Palanning sub  */}
                <Route
                  path="budget_cashflow_list"
                  element={<JournalPage isPredicted />}
                />
                <Route
                  path="budget_cashflow_by_month"
                  element={<CashFlowPredictedPage />}
                />

                <Route path="budget_pnl_list" element={<BudjetPnlListPage />} />
                <Route
                  path="budget_pnl_by_month"
                  element={<BudgetPnlByMonthPage />}
                />

                {/* Info sub */}
                <Route path="currency" element={<CurrencyPage />} />
                <Route path="source" element={<SourcePage />} />
                <Route path="account" element={<AccountPage />} />
                <Route path="direction" element={<DirectionPage />} />
                <Route path="team" element={<TeamPage />} />
                <Route path="contingent" element={<ContingentPage />} />

                {/* Balance */}
                <Route path="balance" element={<BalancePage />} />

                {/* Company */}
                <Route path="company" element={<CompanyPage />} />

                {/* Brnachs */}
                <Route path="branchs" element={<BranchesPage />} />
              </>
            ) : (
              <Route
                path=":id"
                element={profile ? <CompanyPage /> : <div></div>}
              />
            )}
          </Route>

          <Route path="*" element={<Custom404 />} />
        </Routes>

        {isAvailableChat && <ChatPage />}
      </div>
    </Suspense>
  );
}

export default RoutElements;
