import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { IBudgetPnlRes } from "src/app/services/finance/type";
import PaginationFilter from "src/components/filter/PaginationFilter";
import TableActions from "src/components/view/TableActions";
import ContentTop from "src/layout/content/ContentTop";
import { budjetPnlColumns } from "src/static/columns";
import AddBudjetPnl from "./add";
import useBudjetPnlList from "./useList";
import { useTranslation } from "react-i18next";
import FilterDate from "src/components/filter/FilterDate";
import CustomButton from "src/components/common/button";
import DirectionFormItem from "src/components/formItem/DirectionFormItem";
import SubHelperFilters from "src/pages/sub_helper_reports/akt/components/SubHelperFilters";
import { useState } from "react";
import { FilterOutlined } from "@ant-design/icons";

function BudjetPnlListPage() {
  const { t } = useTranslation();
  const {
    isLoading,
    data,
    total,
    handleGet,
    handleDelete,
    handleEdit,
    editData,
    setEditData,
  } = useBudjetPnlList();
  const [filterVisible, setFilterVisible] = useState(false);

  const columns: ColumnsType<IBudgetPnlRes> = [
    ...budjetPnlColumns(t),
    {
      title: t("common.actions"),
      dataIndex: "key",
      key: "key",
      width: 90,
      fixed: "right",
      render: (id, val) => (
        <TableActions
          onDelete={() => handleDelete(id)}
          onEdit={() => handleEdit(val)}
        />
      ),
    },
  ];

  return (
    <div className="journal">
      <ContentTop>
        <CustomButton
          type={filterVisible ? "primary" : "default"}
          height={38}
          onClick={() => setFilterVisible(!filterVisible)}
          icon={<FilterOutlined />}
          style={
            filterVisible
              ? { color: "white", marginBottom: "1.6rem" }
              : { marginBottom: "1.6rem" }
          }
          className={`btn-primary ${!filterVisible && "journal-button"}`}
        >
          {t("common.filter")}
        </CustomButton>

        <AddBudjetPnl
          handleGet={handleGet}
          editData={editData}
          setEditData={setEditData}
        />
      </ContentTop>

      {filterVisible && (
        <SubHelperFilters income outcome amount={false} source date={false}>
          <FilterDate
            label={t("common.date")}
            format="month"
            firthMonth
            noShadow
          />

          <DirectionFormItem
            className="half"
            label={t("subHelper.serviceType")}
            name="service"
          />
        </SubHelperFilters>
      )}

      <div className="journal-table-wrap">
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 1200 }}
          bordered={true}
          className="journal-table"
          pagination={false}
          loading={isLoading}
        />
        {total && <PaginationFilter total={total?.count} />}
      </div>
    </div>
  );
}

export default BudjetPnlListPage;
