import {
  DownloadOutlined,
  FilterOutlined,
  MinusOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Statistic, Table, message } from "antd";
import type { ColumnsType } from "antd/es/table/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { hostName } from "src/app/services/api/const";
import {
  useDeleteJournalMutation,
  useDeleteJournalPaymentCalendarMutation,
  useDeleteJournalPredictedMutation,
  useGenerateJournalMutation,
  useGetJournalMutation,
  useGetJournalPaymentCalendarMutation,
  useGetJournalPredictedMutation,
} from "src/app/services/finance";
import { IJournalResData } from "src/app/services/finance/type";
import { changeJournalAction } from "src/app/slices/journalSlice";
import { useAppDispatch, useTypedSelector } from "src/app/store";
import CustomButton from "src/components/common/button";
import PaginationFilter from "src/components/filter/PaginationFilter";
import TableActions from "src/components/view/TableActions";
import ContentTop from "src/layout/content/ContentTop";
import { handleMakeUrl, redirectToNewTab } from "src/utils";
import AddJounal from "./components/AddJournal";
import JournalFilters from "./components/JournalFilters";
import { journalColumns } from "./constants";
import "./journal.scss";
import { UploadExcel } from "./components/UploadExcel";

interface Props {
  isPredicted?: boolean;
  isPaymentCalendar?: boolean;
}

function JournalPage({ isPredicted, isPaymentCalendar }: Props) {
  const { t } = useTranslation();

  //Predicted section
  const [
    getJournalPredicted,
    { data: predictedData, isLoading: predictedLoading },
  ] = useGetJournalPredictedMutation();

  //Predicted payment calendar
  const [
    getJournalPaymentCalendar,
    { data: paymentCalendarData, isLoading: paymentCalendarLoading },
  ] = useGetJournalPaymentCalendarMutation();

  const dispatch = useAppDispatch();
  //Get section
  const [searchParams] = useSearchParams();
  const [getJournal, { data: journalData, isLoading }] =
    useGetJournalMutation();

  const { journals } = useTypedSelector((state) => state.journal);

  const handleGet = () => {
    let url = handleMakeUrl(searchParams);
    if (isPredicted) getJournalPredicted(url);
    else if (isPaymentCalendar) getJournalPaymentCalendar(url);
    else getJournal(url);
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isPredicted]);

  // All data
  const totalData = isPredicted
    ? predictedData
    : isPaymentCalendar
    ? paymentCalendarData
    : journalData;

  //Delete section
  const [deleteFunc, { isSuccess: deleteStatus }] = useDeleteJournalMutation();
  const [deletePredictedFunc, { isSuccess: deletePredictedStatus }] =
    useDeleteJournalPredictedMutation();
  const [deletePaymentCalendar, { isSuccess: deletePCStatus }] =
    useDeleteJournalPaymentCalendarMutation();
  useEffect(() => {
    if (deleteStatus || deletePredictedStatus) {
      handleGet();
      message.success("Item deleted successfully!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus, deletePredictedStatus]);

  //Edit section
  const [editableData, setEditableData] = useState<IJournalResData>();

  const onEdit = (val: number) => {
    if (isPaymentCalendar) {
      let obj = totalData?.results?.find((el) => el.id === val);
      if (obj) setEditableData({ ...obj, date: obj?.day || "" });
    } else {
      let obj = journals?.find((el) => el.id === val);
      if (obj) setEditableData(obj);
    }
  };

  const columns: ColumnsType<IJournalResData> = [
    {
      title: t("common.date"),
      dataIndex: "date",
      key: "date",
      fixed: "left",
      width: 110,
      render: (_, record) =>
        isPredicted
          ? record.date.slice(0, 7)
          : isPaymentCalendar
          ? record?.day
          : record?.date,
    },
    ...journalColumns(t),
    {
      title: t("common.actions"),
      dataIndex: "key",
      key: "key",
      width: 90,
      render: (key, record) => (
        <TableActions
          onDelete={() => {
            if (isPredicted) deletePredictedFunc(record?.id);
            else if (isPaymentCalendar) deletePaymentCalendar(record?.id);
            else deleteFunc(record?.id);
          }}
          onEdit={() => onEdit(key || record?.id)}
        />
      ),
    },
  ];

  //Generate section
  const [generateJournal, { data: generatedUrl }] =
    useGenerateJournalMutation();

  const generateExcel = () => {
    generateJournal();
  };

  useEffect(() => {
    if (generatedUrl) redirectToNewTab(hostName + generatedUrl.link);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedUrl]);

  //Filter
  const [filterVisible, setFilterVisible] = useState(false);

  //Table height
  const { screenMode } = useTypedSelector((state) => state.layout);
  const [tableHeight, setTableHeight] = useState<number>();
  useEffect(() => {
    setTimeout(() => {
      setTableHeight(window.innerHeight - 163);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenMode]);

  // Upload modal section
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <div className="journal">
      <ContentTop>
        <CustomButton
          type={filterVisible ? "primary" : "default"}
          height={38}
          onClick={() => setFilterVisible(!filterVisible)}
          icon={<FilterOutlined />}
          style={filterVisible ? { color: "white" } : {}}
          className={`btn-primary ${!filterVisible && "journal-button"}`}
        >
          {t("common.filter")}
        </CustomButton>

        <CustomButton
          className="content-top-btn jounal-excel"
          height={38}
          onClick={() => setVisibleModal(true)}
        >
          <div className="content-top-btn-content">
            <UploadOutlined /> Excel file yuborish
          </div>
        </CustomButton>

        {!isPaymentCalendar && (
          <CustomButton
            className="content-top-btn jounal-excel"
            height={38}
            onClick={generateExcel}
          >
            <div className="content-top-btn-content">
              <DownloadOutlined /> {t("common.downloadExcel")}
            </div>
          </CustomButton>
        )}

        <AddJounal
          handleGet={handleGet}
          editableData={editableData}
          setEditableData={setEditableData}
          isPaymentCalendar={isPaymentCalendar}
          isPredicted={isPredicted}
        />
      </ContentTop>

      {(isPredicted || isPaymentCalendar) && (
        <div className="journal-actions">
          <CustomButton
            onClick={() => dispatch(changeJournalAction("income"))}
            className="header-btn-green"
            height={38}
          >
            <PlusOutlined /> {t("common.income")}
          </CustomButton>
          <CustomButton
            onClick={() => dispatch(changeJournalAction("outcome"))}
            className="header-btn-red"
            height={38}
          >
            <MinusOutlined /> {t("common.outcome")}
          </CustomButton>
        </div>
      )}

      {filterVisible && <JournalFilters />}

      <div className="journal-table-wrap">
        <Table
          columns={columns}
          dataSource={totalData?.results}
          scroll={{ x: 1200, y: tableHeight }}
          bordered={true}
          className="journal-table"
          pagination={false}
          loading={isLoading || predictedLoading}
          rowKey={(record) => record.id}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  {t("common.total")}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <Statistic
                    value={totalData?.total?.income}
                    className="table-statistic"
                  />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <Statistic
                    value={totalData?.total?.outcome}
                    className="table-statistic"
                  />
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
        {totalData && <PaginationFilter total={totalData?.count} />}
      </div>

      <UploadExcel visible={visibleModal} setVisibleModal={setVisibleModal} />
    </div>
  );
}

export default JournalPage;
